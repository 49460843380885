//Mixed & General imports
import { signInWithEmailAndPassword } from "firebase/auth"
import allTenantsMapping from "./tenants"
import { auth } from "./config"

const loginUser = async (email, password, tenantName) => {
    try {
        auth.tenantId = allTenantsMapping.filter(tenant => tenant.name === tenantName)[0].id

        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        
        const user = userCredential.user

        return user
    } catch (error) {
        console.error(error)
        throw error
    }
}

export default loginUser
