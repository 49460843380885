//Mixed & General imports
import React from "react"
//Own Components
import GetDataLoadingCircle from "../utils/getDataLoadingCircle"
//Own Util Functions
import GetTheme from "../utils/getTheme"
//Own Constants
import { defaultTheme } from "../../defaults/constants"
//Imports from Material-UI core
import { CssBaseline, Box, Typography } from "@mui/material"
//Imports from Material-UI styles
import { ThemeProvider } from "@mui/material/styles"

class ProfilePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
      isMobileVertical: false,
      theme: defaultTheme,
      dataLoaded: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.CheckMobileView)
    this.CheckMobileView()
    this.SetCurrentUser()
  }

  componentDidUpdate() {
    if (this.state.isMobile && window.innerWidth <= 1000 && window.innerHeight > window.innerWidth && !this.state.isMobileVertical) {
      this.CheckMobileView()
    }

    if (this.state.isMobileVertical && window.innerWidth <= 1700 && window.innerWidth > window.innerHeight && !this.state.isMobile) {
      this.CheckMobileView()
    }
  }

  CheckMobileView = () => {
    // Update the state based on the screen width
    // If you change something here, you must change it in any other page
    const isMobile = window.innerWidth <= 1700 && window.innerWidth > window.innerHeight // Adjust the breakpoint as per your needs
    const isMobileVertical = window.innerWidth <= 1000 && window.innerHeight > window.innerWidth
    this.setState({
      isMobile: isMobile || isMobileVertical,
      isMobileVertical: isMobileVertical
    })
  }

  SetTheme = (currentUser) => {
    const theme = GetTheme(currentUser)
    this.setState({theme: theme})
  }

  SetCurrentUser = async () => {
    let currentUser = undefined
    try {
      currentUser = await getCurrentUser()
    } catch (error) {
      if (process.env.MODE === "local") {console.error(error)}
    }
    this.SetTheme(currentUser)
    this.setState({dataLoaded: true})
  }

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <div style={{overflow: "hidden", position: "relative", width: "100%", height: "100%"}}>
          {this.state.dataLoaded ?
            <Box sx={{width: "100%", height: "100%"}}>
              <Typography variant={this.state.isMobileVertical ? "h4" : "h3"} style={{color: "white", margin: "10px", marginBottom: "20px"}}>Profile Page</Typography>
            </Box>
          :GetDataLoadingCircle()}
        </div>
      </ThemeProvider>
    )
  }
}

export default ProfilePage
