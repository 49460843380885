//Mixed & General imports
import React from "react"
import PropTypes from "prop-types"
//Own Constants
import { defaultTheme } from "../../../defaults/constants"
//Imports from Material-UI core
import CssBaseline from "@mui/material/CssBaseline"
//Imports from Material-UI styles
import { ThemeProvider } from "@mui/material/styles"
//Import for setting the document head
import { Helmet, HelmetProvider } from "react-helmet-async"

const Layout = ({ title, children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <main>
          {children}
        </main>
      </HelmetProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Layout
