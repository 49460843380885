//Mixed & General imports
import React from "react"
import mailgo from "mailgo"
//Own Util Functions
import GetTheme from "../utils/getTheme"
//Own Constants
import { defaultTheme } from "../../defaults/constants"
//Imports from Material-UI core
import { CssBaseline, Typography, Box, Button, Snackbar } from "@mui/material"
//Imports from Material-UI lab
import { Alert } from "@mui/lab"
//Imports from Material-UI styles
import { ThemeProvider } from "@mui/material/styles"

class ImpressumPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
      isMobileVertical: false,
      theme: defaultTheme,
      currentUser: undefined,
      businessMailPartA: "info",
      businessMailPartB: "cfg-ag.com",
      showCopiedMail: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.CheckMobileView)
    this.CheckMobileView()
    this.SetCurrentUser()
    mailgo({dark: true, lang: "de"})
  }

  componentDidUpdate() {
    if (this.state.isMobile && window.innerWidth <= 1000 && window.innerHeight > window.innerWidth && !this.state.isMobileVertical) {
      this.CheckMobileView()
    }

    if (this.state.isMobileVertical && window.innerWidth <= 1700 && window.innerWidth > window.innerHeight && !this.state.isMobile) {
      this.CheckMobileView()
    }
  }

  CheckMobileView = () => {
    // Update the state based on the screen width
    // If you change something here, you must change it in any other page
    const isMobile = window.innerWidth <= 1700 && window.innerWidth > window.innerHeight // Adjust the breakpoint as per your needs
    const isMobileVertical = window.innerWidth <= 1000 && window.innerHeight > window.innerWidth
    this.setState({
      isMobile: isMobile || isMobileVertical,
      isMobileVertical: isMobileVertical
    })
  }

  SetTheme = (currentUser) => {
    const theme = GetTheme(currentUser)
    this.setState({theme: theme})
  }

  SetCurrentUser = async () => {
    let currentUser = undefined
    try {
      currentUser = await getCurrentUser()
    } catch (error) {
      if (process.env.MODE === "local") {console.error(error)}
    }
    this.SetTheme(currentUser)
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
      <CssBaseline />
        <Typography variant={this.state.isMobileVertical ? "h4" : "h3"} style={{color: "white", margin: "10px", marginBottom: "20px"}}>Impressum</Typography>
        <Typography variant="h5" style={{color: "white", margin: "10px", marginBottom: "20px"}}>Angaben gemäß § 5 TMG:</Typography>
        <Typography variant="h6" style={{color: "white", margin: "10px"}}>CFG AG</Typography>
        <Typography variant="subtitle1" style={{color: "white", margin: "10px", fontWeight: 600}}>Verwaltungsrat</Typography>
        <Typography variant="subtitle1" style={{color: "white", margin: "10px"}}>Franz Köpper</Typography>
        <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginBottom: "30px"}}>Gerhard Arminger</Typography>
        <Box sx={{display: "flex", marginBottom: "10px"}}>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px", fontWeight: 600}}>E-Mail:</Typography>
          <a style={{textDecoration: "none", marginTop: "6px"}} className="dark" href="#mailgo" data-address={this.state.businessMailPartA} data-domain={this.state.businessMailPartB}>
            <Button style={{color: "#D2D2D2"}}>{this.state.businessMailPartA + "@" + this.state.businessMailPartB}</Button>
          </a>
        </Box>
        <Box sx={{display: "flex", marginBottom: "10px"}}>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px", fontWeight: 600}}>Handelsregisteramt:</Typography>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px"}}>Kanton Thurgau</Typography>
        </Box>
        <Box sx={{display: "flex", marginBottom: "10px"}}>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px", fontWeight: 600}}>Handelsregister-Nummer:</Typography>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px"}}>CHE-115.182.229</Typography>
        </Box>
        <Box sx={{display: "flex", marginBottom: "30px"}}>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px", fontWeight: 600}}>Ust.-ID-Nr.:</Typography>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px"}}>CHE-115.182.229</Typography>
        </Box>
        <Box sx={{display: "flex", marginBottom: "30px"}}>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px", fontWeight: 600}}>Inhaltlich verantwortlich:</Typography>
          <Typography variant="subtitle1" style={{color: "white", margin: "10px", marginRight: "10px"}}>Michael May</Typography>
        </Box>
        {this.state.showCopiedMail ?
          <Snackbar open={true} autoHideDuration={5000} onClose={() => {this.setState({showCopiedMail: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showCopiedMail: false})}} severity="success">
              Die E-Mail wurde erfolgreich in die Zwischenablage kopiert. Wir freuen uns auf Ihr Anliegen.
            </Alert>
          </Snackbar>
        :null}
      </ThemeProvider>
    )
  }
}

export default ImpressumPage
