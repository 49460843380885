//Mixed & General imports
import React from "react"
import PropTypes from "prop-types"
//Imports from Material-UI core
import Button from "@mui/material/Button"
//Imports from Material-UI styles
import { styled } from "@mui/material/styles"

const MyButton = styled(Button)(({ backgroundcolor }) => ({
  backgroundColor: backgroundcolor,
  color: "#DFDFDF",
  margin: "16px",
  textShadow: "2px 2px 4px #333333",
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "translateY(-3px)"
  }
}))

function HeaderButton(props) {
  const { backgroundcolor, ...other } = props
  return <MyButton backgroundcolor={backgroundcolor} {...other} />
}

HeaderButton.propTypes = {
  backgroundcolor: PropTypes.string.isRequired
}

export default HeaderButton
