//Mixed & General imports
import axios from "axios"

export default async function(reCaptchaToken, accessToken) {
  const response = await axios({
    method: "POST",
    url: process.env.REACT_APP_MODE === "production"
    ? "URL-TBD"
    : process.env.REACT_APP_MODE === "development" ? "https://cr-dcp-backend-237052482020.europe-west6.run.app/api/v1/auth/login" : "http://127.0.0.1:8080/api/v1/auth/login",
    headers: {"recaptcha-token": reCaptchaToken, "id-token": accessToken}
  })
  return response
}