//Mixed & General imports
import React from "react"
//Imports from Material-UI v5
import { Paper, CircularProgress, circularProgressClasses } from "@mui/material"

export default function GetCircularProgress(big=false) {
  return (
    <CircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: "#C2C1C1",
        animationDuration: "1000ms",
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: "round",
        },
      }}
      size={big ? 50 : 30}
      thickness={4}
    />
  )
}
