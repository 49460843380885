//Mixed & General imports
import React from "react"
import headerGlobalClass from "./utils/headerGlobalClass"
import { withRouter } from "react-router-dom"
import Avvvatars from "avvvatars-react"
import cfgLogo from "../../public/cfg-logo.png"
import kvaLogo from "../../public/kva-logo.png"
//Own Components
import HeaderButton from "./components/headerButton"
//Own Util Functions
import GetTheme from "../utils/getTheme"
//Own Constants
import { defaultTheme } from "../../defaults/constants"
//Imports from Material-UI v5
import { AppBar, Box, Typography, IconButton, Popover, MenuItem } from "@mui/material"
//Imports from Material-UI v5 icons
import { MenuOutlined, MenuOpenOutlined, SettingsOutlined, ExitToAppOutlined } from "@mui/icons-material"
//Imports from Material-UI styles
import { alpha, styled } from "@mui/material/styles"

const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 10,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: "linear-gradient(60deg, #404040, #4b4848)",
    boxShadow: "0 3px 5px 2px rgba(14, 173, 105, .3)",
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`
  }
}))

class Header extends React.Component {
  constructor(props) {
    super(props)

    headerGlobalClass.setClass(this)

    this.state = {
      isMobile: false,
      isMobileVertical: false,
      theme: defaultTheme,
      currentUrl: "",

      hamburgerMenuAnchor: null,

      currentUser: {},
      userLoggedIn: false,

      headerDropDownOpened: false,
      headerDropDownAnchor: null
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.CheckMobileView)
    document.addEventListener("keydown", this.HandleEsc, false)

    this.CheckMobileView()
    this.SetCurrentUser()

    this.setState({currentUrl: window.location.href})
  }

  componentDidUpdate() {
    if (this.state.currentUrl !== window.location.href) {
      this.setState({currentUrl: window.location.href})
    }

    if (this.state.isMobile && window.innerWidth <= 1000 && window.innerHeight > window.innerWidth && !this.state.isMobileVertical) {
      this.CheckMobileView()
    }

    if (this.state.isMobileVertical && window.innerWidth <= 1700 && window.innerWidth > window.innerHeight && !this.state.isMobile) {
      this.CheckMobileView()
    }
  }

  CheckMobileView = () => {
    // Update the state based on the screen width
    // If you change something here, you must change it in any other page
    const isMobile = window.innerWidth <= 1700 && window.innerWidth > window.innerHeight // Adjust the breakpoint as per your needs
    const isMobileVertical = window.innerWidth <= 1000 && window.innerHeight > window.innerWidth
    this.setState({
      isMobile: isMobile || isMobileVertical,
      isMobileVertical: isMobileVertical
    })
  }

  SetTheme = (currentUser) => {
    const theme = GetTheme(currentUser)
    this.setState({theme: theme})
  }

  SetCurrentUser = async () => {
    let currentUser = undefined
    try {
      currentUser = await getCurrentUser()
    } catch (error) {
      if (process.env.MODE === "local") {console.error(error)}
    }
    this.SetTheme(currentUser)

    if (currentUser) { // Only do this, if a user is logged in
      this.setState({
        currentUser: currentUser,
        userLoggedIn: true
      })
    }
  }

  HandleHamburgerMenuOpen = (event) => {
    this.setState({hamburgerMenuAnchor: event.currentTarget})
  }

  HandleHamburgerMenuClose = () => {
    this.setState({hamburgerMenuAnchor: null})
  }

  GoToPlanningPage = () => {
    if (this.state.userLoggedIn || process.env.REACT_APP_MODE === "local") {
      this.props.history.push("/planning")
    } else {
      this.props.history.push("/")
    }
  }

  OpenHeaderDropdown = (event) => {
    this.setState({
      headerDropDownOpened: true,
      headerDropDownAnchor: event.currentTarget
    })
  }

  CloseHeaderDropdown = (event, clickedItemName) => {
    if (!this.state.userLoggedIn) {
      this.setState({headerDropDownAnchor: event.currentTarget}, () => {
        this.OpenDropdownSelector(this.state.headerDropDownAnchor, clickedItemName)
        if (this.state.isMobileVertical) {
          this.HandleHamburgerMenuClose()
        } else {
          this.setState({
            headerDropDownOpened: false,
            headerDropDownAnchor: null
          })
        }
      })
    } else {
      if (event !== undefined) { //If this is true, an item with another menu was clicked in the HeaderDropdown
        this.OpenDropdownSelector(this.state.headerDropDownAnchor, clickedItemName)
      }
      this.setState({
        headerDropDownOpened: false,
        headerDropDownAnchor: null
      })
    }
  }

  GoToProfilePage = () => {
    this.props.history.push("/profile")
  }

  GoToGuidePage = () => {
    this.props.history.push("/guide")
  }

  GoToFaqPage = () => {
    this.props.history.push("/faq")
  }

  GoToImpressumPage = () => {
    this.props.history.push("/impressum")
  }

  LogoutUser = async (banned=false) => {
    this.setState({userLoggedIn: false}, async () => {await logout()})
  }

  render() {
    const isHamburgerMenuOpen = Boolean(this.state.hamburgerMenuAnchor)

    return (
      <div style={{marginBottom: "20px"}}>
        <AppBar position="relative" sx={{background: "linear-gradient(60deg, rgba(64, 64, 64, 0.3), rgba(75, 72, 72, 0.3))", zIndex: 1000}}>
          <Box display="flex" p={1} width="100%">
            <Box display="flex" alignContent="flex-start" style={{cursor: "pointer"}}>
              <img src={cfgLogo} alt="cfgLogo" width={112} height={64} onClick={() => {this.GoToPlanningPage()}}/>
              <img src={kvaLogo} alt="kvaLogo" width={158} height={64} style={{marginLeft: "20px", marginRight: "10px"}} />
            </Box>
            {!this.state.isMobileVertical ?
              <Box alignContent="flex-start" style={{marginLeft: "10px"}}>
                <Typography variant={this.state.isMobileVertical ? "h5": "h4"} style={{color: "#DFDFDF", marginBottom: "-5px", textShadow: "2px 2px 4px #333333", cursor: "pointer"}} onClick={() => {this.GoToPlanningPage()}}>CFG AG</Typography>
                <Typography variant={this.state.isMobileVertical ? "subtitle2": "h6"} style={{color: "#DFDFDF", textShadow: "2px 2px 4px #333333"}}>DCP Planung</Typography>
              </Box>
            :null}
            {this.state.isMobile && !this.state.isMobileVertical ?
              <Box alignContent="flex-end" flexGrow={1}></Box>
            :null}
            {this.state.isMobile && !this.state.isMobileVertical ?
              <Box style={{display: this.state.isMobileVertical ? "" : "flex"}}>
                {!this.state.isMobileVertical ?
                  <a style={{textDecoration: "none"}} href={"/login"}>
                    <HeaderButton backgroundcolor={this.state.currentUrl.includes("/login") ? "#212121" : ""}
                    >Anmelden</HeaderButton>
                  </a>
                :null}
              </Box>
            :null}
            {this.state.isMobile ? // Render hamburger menu for mobile view
              <>
                {this.state.isMobileVertical ?
                  <Box alignContent="flex-end" flexGrow={1}></Box>
                :null}
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  aria-controls="menu"
                  aria-haspopup="true"
                  style={{height: "50%", marginRight: "10px", marginTop: "5px", marginLeft: "10px"}}
                  onClick={(event) => {this.HandleHamburgerMenuOpen(event)}}
                >
                  {isHamburgerMenuOpen ? <MenuOpenOutlined style={{color: "#D2D2D2"}} fontSize="large" /> : <MenuOutlined style={{color: "#D2D2D2"}} fontSize="large" />}
                </IconButton>
              </>
            :
              <Box alignContent="flex-start" flexGrow={1} style={{marginLeft: "10px"}}>
                <HeaderButton backgroundcolor={this.state.currentUrl.includes("/guide") ? "#212121" : ""}
                  onClick={() => {this.GoToGuidePage()}}
                >Guide</HeaderButton>
                <HeaderButton backgroundcolor={this.state.currentUrl.includes("/faq") ? "#212121" : ""}
                  onClick={() => {this.GoToFaqPage()}}
                >FAQ</HeaderButton>
                <HeaderButton style={{color: "#DFDFDF"}} backgroundcolor={this.state.currentUrl.includes("/planning") ? "#212121" : ""}
                  onClick={() => {this.GoToPlanningPage()}}
                >Planung durchführen</HeaderButton>
                <HeaderButton backgroundcolor={this.state.currentUrl.includes("/impressum") ? "#212121" : ""}
                  onClick={() => {this.GoToImpressumPage()}}
                >Impressum</HeaderButton>
              </Box>
            }
            {!this.state.isMobile && !this.state.isMobileVertical ?
              <Box style={{display: this.state.isMobileVertical ? "" : "flex", flexDirection: "row", alignItems: "center"}}>
                {!this.state.isMobileVertical ?
                  <a style={{textDecoration: "none"}} href={"/login"}>
                    <HeaderButton backgroundcolor={this.state.currentUrl.includes("/login") ? "#212121" : ""}
                    >Anmelden</HeaderButton>
                  </a>
                :null}
              </Box>
            :null}
            {this.state.userLoggedIn ?
              <Box alignContent="flex-start" style={{cursor: "pointer"}}>
                {this.state.userLoggedIn ?
                  <div onClick={(event) => {this.OpenHeaderDropdown(event)}}>
                    <Avvvatars value={this.state.currentUser.name} size={64} shadow={true} borderSize={4} borderColor={"#1A1A1A"}/>
                  </div>
                :null}
              </Box>
            :null}
          </Box>
          {this.state.isMobileVertical ?
            <Box display="flex" style={{marginLeft: "10px", alignContent: "center", flexDirection: "row"}}>
              <Typography variant={this.state.isMobile ? "h5": "h4"} style={{color: "#DFDFDF", marginBottom: "5px", marginTop: "-5px", textShadow: "2px 2px 4px #333333"}}>CFG AG</Typography>
              <Typography variant={this.state.isMobile ? "subtitle2": "h6"} style={{color: "#DFDFDF", marginLeft: "10px", marginTop: "2.5px", textShadow: "2px 2px 4px #333333"}}>DCP Planung</Typography>
              <Box flexGrow={1}></Box>
              {!this.state.userLoggedIn ?
                <a style={{textDecoration: "none"}} href={"/login"}>
                  <HeaderButton backgroundcolor={this.state.currentUrl.includes("/login") ? "#212121" : ""} style={{marginTop: "-20px", marginBottom: "10px", marginRight: "3px"}}
                  >Anmelden</HeaderButton>
                </a>
              :null}
            </Box>
          :null}
          {this.state.userLoggedIn ?
            <>
              <Popover
                id="customized-header-popover"
                anchorEl={this.state.headerDropDownAnchor}
                keepMounted
                open={this.state.headerDropDownOpened}
                onClose={() => {this.CloseHeaderDropdown()}}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                PaperProps={{
                  sx: {
                    background: "linear-gradient(60deg, #404040, #4b4848)",
                    color: "white",
                    mt: 1.5,
                    ml: 0.5,
                    overflow: this.state.isMobile ? "auto" : "inherit",
                    boxShadow: "0 3px 5px 2px rgba(14, 173, 105, .3)",
                    border: 1,
                    borderColor: "black",
                    width: 190,
                  }
                }}
              >
                <ArrowStyle className="arrow" />
                <MenuItem sx={{typography: "subtitle1", py: 1, px: 2.5, textShadow: "4px 4px 8px #000000", '&:hover': {backgroundColor: "#212121", borderRadius: "4px"}}} onClick={() => {this.GoToProfilePage(), this.CloseHeaderDropdown()}}>
                  <SettingsOutlined style={{color: "#D2D2D2", marginRight: "15px", boxShadow: "2px 2px 4px #333333", borderRadius: "4px"}} fontSize="medium"/>
                  Profileinstellungen
                </MenuItem>
                <MenuItem sx={{typography: "subtitle1", py: 1, px: 2.5, textShadow: "4px 4px 8px #000000", '&:hover': {backgroundColor: "#212121", borderRadius: "4px"}}} onClick={() => {this.LogoutUser(), this.CloseHeaderDropdown()}}>
                  <ExitToAppOutlined style={{color: "#D2D2D2", marginRight: "15px", boxShadow: "2px 2px 4px #333333", borderRadius: "4px"}} fontSize="medium"/>
                  Abmelden
                </MenuItem>
              </Popover>
            </>
          :null}
        </AppBar>
        {/*HamburgerMenu for Mobile View, if you change something here, you must also change it above for Desktop View*/}
        <Popover
          id="customized-hamburger-popover"
          anchorEl={this.state.hamburgerMenuAnchor}
          keepMounted
          open={isHamburgerMenuOpen}
          onClose={() => {this.HandleHamburgerMenuClose()}}
          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
          transformOrigin={{vertical: "top", horizontal: "right"}}
          PaperProps={{
            sx: {
              background: "linear-gradient(60deg, #404040, #4b4848)",
              color: "white",
              mt: 1.5,
              ml: 0.5,
              overflow: this.state.isMobile ? "auto" : "inherit",
              boxShadow: "0 3px 5px 2px rgba(14, 173, 105, .3)",
              border: 1,
              borderColor: "black",
              width: 190,
            }
        }}
        >
          <ArrowStyle className="arrow" />
          <MenuItem sx={{typography: "subtitle1", py: 1, px: 2.5, textShadow: "4px 4px 8px #000000", '&:hover': {backgroundColor: "#212121", borderRadius: "4px"}, color: "#D2D2D2"}}
            onClick={() => {this.GoToGuidePage(); this.HandleHamburgerMenuClose();}}
          >Guide</MenuItem>
          <MenuItem sx={{typography: "subtitle1", py: 1, px: 2.5, textShadow: "4px 4px 8px #000000", '&:hover': {backgroundColor: "#212121", borderRadius: "4px"}, color: "#D2D2D2"}}
            onClick={() => {this.GoToFaqPage(); this.HandleHamburgerMenuClose();}}
          >FAQ</MenuItem>
          <MenuItem sx={{typography: "subtitle1", py: 1, px: 2.5, textShadow: "4px 4px 8px #000000", '&:hover': {backgroundColor: "#212121", borderRadius: "4px"}, color: "#DFDFDF"}}
            onClick={() => {this.GoToPlanningPage(); this.HandleHamburgerMenuClose();}}
          >Planung durchführen</MenuItem>
          <MenuItem sx={{typography: "subtitle1", py: 1, px: 2.5, textShadow: "4px 4px 8px #000000", '&:hover': {backgroundColor: "#212121", borderRadius: "4px"}, color: "#D2D2D2"}}
            onClick={() => {this.GoToImpressumPage(); this.HandleHamburgerMenuClose();}}
          >Impressum</MenuItem>
        </Popover>
      </div>
    );
  }
}

export default withRouter(Header)
