//Mixed & General imports
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
//Own Components
import Login from "./login"

const LoginPage = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_WEBSITE_KEY}>
      <Login />
    </GoogleReCaptchaProvider>
  )
}

export default LoginPage
