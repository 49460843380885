//Mixed & General imports
import React from "react"
import { nanoid } from "nanoid"
import { DataGrid } from "@mui/x-data-grid"
//Own Components
import GetDataLoadingCircle from "../../utils/getDataLoadingCircle"
//Own Util Functions
import CalculatePlannedTons from "../utils/calculatePlannedTons"
import CalculatePlannedTonsDeviation from "../utils/calculatePlannedTonsDeviation"
import { SetDynamicColumnWidths, GetSumOfColumnWidths } from "../../utils/datagridConstruction"
//Imports from Material-UI core
import { Box, Typography } from "@mui/material"

class PlanningOverview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      planningPage: props.planningPage,
      isMobile: props.isMobile,
      isMobileVertical: props.isMobileVertical,
      dataLoaded: false,

      rows: [],
      columns: [
        {field: "hub", headerName: "Hub", editable: false, sortable: false,
          renderCell: (params) => {
            const [color, deviationPercentage] = CalculatePlannedTonsDeviation(params.row.plannedTons, params.row.tonsPerWeek)
            return (<span style={{color: color}}>{params.value}</span>)
          }
        },
        {field: "tonsPerWeek", headerName: "Tonnen pro Woche", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "plannedTons", headerName: "Aktuell geplante Tonnen", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        }
      ]
    }
  }

  componentDidMount() {
    this.CreateRowsFromHubs()
  }

  CreateRowsFromHubs = () => {
    const hubs = this.state.planningPage.state.hubs
    let rows = []

    for (const hub of hubs) {
      const row = {
        id: nanoid(12),
        hub: hub.hubData.name,
        tonsPerWeek: hub.hubData.tonsPerWeek,
        plannedTons: CalculatePlannedTons(hub.dataPerDay)
      }

      rows.push(row)
    }

    this.setState({rows: rows}, () => {SetDynamicColumnWidths(this, this.state.columns, rows)})
  }

  render() {
    return (
      <Box style={{width: GetSumOfColumnWidths(this.state.columns), marginRight: "20px"}}>
        <Typography variant={this.state.isMobileVertical ? "h6" : "h5"} style={{color: "#DFDFDF", fontWeight: "bold"}}>Zusammenfassung zu allen Hubs</Typography>
        {this.state.dataLoaded ? (
          <DataGrid
            sx={{
              background: "transparent",
              border: 1,
              borderColor: "black",
              color: "#FFFFFF",
              "& .MuiDataGrid-cell": {
                backgroundColor: "transparent"
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "transparent"
              },
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden"
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important"
              },
              "& .MuiDataGrid-overlay": {
                background: "transparent",
                color: "#FFFFFF"
              }
            }}
            disableColumnMenu
            hideFooter
            rows={this.state.rows}
            columns={this.state.columns}
          />
        ) : (
          GetDataLoadingCircle()
        )}
      </Box>
    )
  }
}

export default PlanningOverview
