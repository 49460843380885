//Imports from Material-UI styles
import { createTheme } from "@mui/material/styles"

// Default colors
/*
#DFDFDF (white)
#333333 (dark-grey)
#C2C1C1 (grey)
#D2D2D2 (light-grey)
#29BC4B (green)
#F3B607 (yellow)
#EA4434 (red)
*/

// Default linear-gradients
/*
normal:
  linear-gradient(60deg, #404040, #4b4848)
with opacity:
  linear-gradient(60deg, rgba(64, 64, 64, 0.3), rgba(75, 72, 72, 0.3))
*/

export const defaultTheme = createTheme({
  palette: {
    mode: "dark"
  },
  typography: {
    fontFamily: "Montserrat"
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(90deg, #212121, #212121)"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            transform: "translateY(-3px)"
          },
          position: "relative",
          overflow: "hidden",
          transition: "transform 0.3s"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#333333 !important",
          "&:hover": {
            backgroundColor: "#212121 !important"
          },
          "&:focus": {
            backgroundColor: "#212121 !important"
          }
        }
      }
    }
  }
})
