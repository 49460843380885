//Mixed & General imports
import React from "react"
import { nanoid } from 'nanoid'
import { DataGrid } from "@mui/x-data-grid"
//Own Components
import GetDataLoadingCircle from "../../utils/getDataLoadingCircle"
//Own Util Functions
import { SetDynamicColumnWidths, GetSumOfColumnWidths } from "../../utils/datagridConstruction"
//Imports from Material-UI core
import { Box, Typography } from "@mui/material"

class PlanningCarsInformation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      planningPage: props.planningPage,
      isMobile: props.isMobile,
      isMobileVertical: props.isMobileVertical,
      dataLoaded: false,

      rows: [],
      columns: [
        {field: "car", headerName: "Fahrzeug", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "capacity", headerName: "Ladekapazität", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "location", headerName: "Standort", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "monday", headerName: "Mo", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "tuesday", headerName: "Di", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "wednesday", headerName: "Mi", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "thursday", headerName: "Do", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "friday", headerName: "Fr", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "saturday", headerName: "Sa", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        },
        {field: "sunday", headerName: "So", editable: false, sortable: false,
          renderCell: (params) => {return (<span style={{color: "#FFFFFF"}}>{params.value}</span>)}
        }
      ]
    }
  }

  componentDidMount() {
    this.CreateRowsFromCars()
  }

  SumAllToursOfCarForDay = (carName, day) => {
    const hubs = this.state.planningPage.state.hubs
    let sumTours = 0

    //... !isNextDay and day == day
    for (const hub of hubs) {
      for (const data of hub.dataPerDay) {
        if (data.day == day && !data.isNextDay && data.car.name == carName && data.tours) {
          sumTours += data.tours
        }
      }
    }

    return sumTours
  }

  CreateRowsFromCars = () => {
    const cars = this.state.planningPage.state.cars
    let rows = []

    for (const car of cars) {
      const row = {
        id: nanoid(12),
        car: car.name,
        capacity: car.capacity,
        location: car.location,
        monday: this.SumAllToursOfCarForDay(car.name, "Montag"),
        tuesday: this.SumAllToursOfCarForDay(car.name, "Dienstag"),
        wednesday: this.SumAllToursOfCarForDay(car.name, "Mittwoch"),
        thursday: this.SumAllToursOfCarForDay(car.name, "Donnerstag"),
        friday: this.SumAllToursOfCarForDay(car.name, "Freitag"),
        saturday: this.SumAllToursOfCarForDay(car.name, "Samstag"),
        sunday: this.SumAllToursOfCarForDay(car.name, "Sonntag")
      }

      rows.push(row)
    }

    this.setState({rows: rows}, () => {SetDynamicColumnWidths(this, this.state.columns, rows)})
  }

  render() {
    return (
      <Box style={{width: GetSumOfColumnWidths(this.state.columns) + 220, marginRight: "20px"}}>
        <Typography variant={this.state.isMobileVertical ? "h6" : "h5"} style={{color: "#DFDFDF", fontWeight: "bold"}}>Fahrzeuginformationen</Typography>
        {this.state.dataLoaded ?
          <DataGrid
            sx={{
              background: "transparent",
              border: 1,
              borderColor: "black",
              color: "#FFFFFF",
              "& .MuiDataGrid-cell": {
                backgroundColor: "transparent",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "transparent",
              },
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important"
              },
              "& .MuiDataGrid-overlay": {
                background: "transparent",
                color: "#FFFFFF"
              }
            }}
            disableColumnMenu
            hideFooter
            rows={this.state.rows}
            columns={this.state.columns}
          />
        :GetDataLoadingCircle()}
      </Box>
    )
  }
}
  
export default PlanningCarsInformation
  