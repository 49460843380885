//Own Constants
import { defaultTheme } from "../../defaults/constants"

export default function GetTheme(currentUser) {
  let theme = undefined

  if (currentUser) {
    theme = defaultTheme
    theme.palette.type = currentUser.selectedDesignMode
  }
  else {
    theme = defaultTheme
  }

  return theme
}
