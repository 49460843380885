// Mixed & General imports
import React from "react"
import PropTypes from "prop-types"
// Imports from Material-UI core
import Button from "@mui/material/Button"
// Imports from Material-UI styles
import { styled } from "@mui/material/styles"

const getBackground = (color) => {
  switch (color) {
    case "green": return "linear-gradient(45deg, #29BC4B 30%, #29BC4B 90%)"
    case "blue": return "linear-gradient(45deg, #299EC4 30%, #299EC4 90%)"
    case "yellow": return "linear-gradient(45deg, #F3B607 30%, #F3B607 90%)"
  }
}

const getBoxShadow = (color) => {
  switch (color) {
    case "green": return "0 3px 5px 2px rgba(40, 202, 77, .3)"
    case "blue": return "0 3px 5px 2px rgba(41, 158, 196, .3)"
    case "yellow": return "0 3px 5px 2px rgba(243, 182, 7, .3)"
  }
}

const getColor = (fontColor) => {
  return fontColor === "red" ? "red" : "black"
}

const getHeight = (height) => {
  return height ? height : 50
}

const MyButton = styled(Button)((props) => ({
  background: getBackground(props.props.color),
  border: 0,
  borderRadius: 4,
  boxShadow: getBoxShadow(props.props.color),
  color: getColor(props.props.fontColor),
  height: getHeight(props.props.height),
  padding: "0 15px",
  margin: 10
}))

function PlanningButton(props) {
  const { color, fontColor, height, ...other } = props
  return <MyButton {...other} props={{color, fontColor, height}} />
}

PlanningButton.propTypes = {
  color: PropTypes.oneOf(["green", "blue", "yellow"]),
  fontColor: PropTypes.string,
  height: PropTypes.number
}

export default PlanningButton
