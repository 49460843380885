//Mixed & General imports
import React from "react"
//Own Util Functions
import CalculatePlannedTons from "../utils/calculatePlannedTons"
import CalculatePlannedTonsDeviation from "../utils/calculatePlannedTonsDeviation"
//Imports from Material-UI core
import { Card, CardContent, Typography, Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material"

class PlanningHub extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      planningPage: props.planningPage,
      isMobile: props.isMobile,
      isMobileVertical: props.isMobileVertical,
      dataLoaded: false,

      weeks: props.weeks,
      dates: props.weeks[props.selectedWeekIndex].dates,
      selectedWeekIndex: props.selectedWeekIndex,
      hubIndex: props.hubIndex,
      hubData: props.hubData,
      dataPerDay: props.dataPerDay,

      selectToursValues: [],
      updatedToursInputs: [false, false, false, false, false, false, false, false],
      selectedToursIndexes: [0, 0, 0, 0, 0, 0, 0, 0],
      allTours: ["Keine", "Keine", "Keine", "Keine", "Keine", "Keine", "Keine", "Keine"],

      updatedCarsInputs: [false, false, false, false, false, false, false, false],
      selectedCarsIndexes: [0, 0, 0, 0, 0, 0, 0, 0],
      allCars: ["Keins", "Keins", "Keins", "Keins", "Keins", "Keins", "Keins", "Keins"],
      
      updatedNextWeekDayInput: false,
      selectedNextWeekDayIndex: 0,
      allNextWeekDays: ["Keiner", "Montag", "Dienstag", "Mittwoch"]
    }
  }

  componentDidMount() {
    this.PrepareHub()
  }

  PrepareHub = () => {
    const dates = this.PrepareAllDates()
    const {selectToursValues, allTours} = this.PrepareToursSelectValues()
    const {selectCarsValues, allCars} = this.PrepareCarsSelectValues(this.state.dataPerDay)

    this.state.planningPage.setState({selectCarsValues: selectCarsValues}, () => {
      this.setState({
        dataLoaded: true,
        dates: dates,
        selectToursValues: selectToursValues,
        allTours: allTours,
        allCars: allCars
      })
    })
  }

  PrepareAllDates = () => {
    const weeks = this.state.weeks
    let dates = this.state.dates

    if (weeks.length > this.state.selectedWeekIndex + 1) {
      const nextWeek = weeks[this.state.selectedWeekIndex + 1]

      dates.push(nextWeek.dates[0])
      dates.push(nextWeek.dates[1])
      dates.push(nextWeek.dates[2])
    }

    return dates
  }

  PrepareToursSelectValues = () => {
    const hubData = this.state.hubData
    const dataPerDay = this.state.dataPerDay

    let selectToursValues = []
    if (hubData.minTours !== undefined && hubData.maxTours !== undefined && hubData.minTours <= hubData.maxTours) {
      selectToursValues.push("Keine")
      for (let i = hubData.minTours; i <= 4; i++) { //maxTours should always be 4
        selectToursValues.push(i.toString())
      }
    }

    let allTours = []
    for (let i = 0; i < dataPerDay.length; i++) {
      if (dataPerDay[i].tours) {
        allTours.push(dataPerDay[i].tours.toString())
      } else {
        allTours.push(selectToursValues[0])
      }
    }

    return {selectToursValues, allTours}
  }

  UpdateCurrentHubInHubs = (dataPerDay) => {
    let hubs = this.state.planningPage.state.hubs
    let hubData = this.state.hubData

    hubData.plannedTons = CalculatePlannedTons(dataPerDay)

    hubs[this.state.hubIndex].hubData = hubData
    hubs[this.state.hubIndex].dataPerDay = dataPerDay
    
    this.state.planningPage.setState({
      planningOverviewKey: Math.random(),
      hubs: hubs
    })

    return hubs
  }

  GetAvailableCarsOnDays = (hubs) => {
    const availableCarsOnDays = this.state.planningPage.DetermineAvailableCarsOnDays(hubs)
    return availableCarsOnDays
  }

  PrepareCarsSelectValues = (dataPerDay) => {
    const availableCarsOnDays = this.GetAvailableCarsOnDays(this.state.planningPage.state.hubs)

    let selectCarsValues = []
    let allCars = []
    for (const data of dataPerDay) {
      const day = data.isNextDay ? `${data.day}-2` : data.day
      for (const availableCarOnDay of availableCarsOnDays) {
        if (day === availableCarOnDay.day) {
          selectCarsValues.push(["Keins", ...availableCarOnDay.cars.map(car => car.name)])
        }
      }

      if (data.car.name) {
        allCars.push(data.car.name)
      } else {
        allCars.push("Keins")
      }
    }

    return {selectCarsValues, allCars}
  }

  GetHubDetails = () => {
    const hubData = this.state.hubData
    const plannedTons = CalculatePlannedTons(this.state.dataPerDay)
    const [color, deviationPercentage] = CalculatePlannedTonsDeviation(plannedTons, hubData.tonsPerWeek)

    return (
      <>
        <span style={{marginRight: "20px"}}>Tonnen pro Woche: {hubData.tonsPerWeek} </span>
        <span style={{marginRight: "20px", color: color }}>Aktuell geplante Tonnen: {plannedTons}</span>
        <span style={{marginRight: "20px"}}>Minimale Touren: {hubData.minTours}</span>
        <span>Maximale Touren: {hubData.maxTours}</span>
      </>
    )
  }

  ChangedToursInput = (inputValue, index) => {
    const hubData = this.state.hubData
    let selectToursValues = this.state.selectToursValues
    let allTours = this.state.allTours
    let dataPerDay = this.state.dataPerDay

    for (let i = 0; i < selectToursValues.length; i++) {
      if (i === inputValue) {
        allTours[index] = selectToursValues[i]
        let selectedToursIndexes = this.state.selectedToursIndexes
        selectedToursIndexes[index] = inputValue
        this.setState({selectedToursIndexes: selectedToursIndexes}, () => {
          if (selectToursValues[i] > hubData.maxTours) {
            this.state.planningPage.setState({showSaveProposalMaxToursWarning: true})
          }
        })
        break
      }
    }

    let updatedToursInputs = this.state.updatedToursInputs
    updatedToursInputs[index] = false

    for (let i = 0; i < allTours.length; i++) {
      if (i === index) {
        dataPerDay[i].tours = allTours[i] !== "Keine" ? parseInt(allTours[i]) : undefined
        break
      }
    }

    this.setState({
      allTours: allTours,
      dataPerDay: dataPerDay
    }, () => {
      this.setState({updatedToursInputs: updatedToursInputs})
      this.UpdateCurrentHubInHubs(dataPerDay)
    })
  }

  ChangedCarsInput = (inputValue, index) => {
    let allCars = this.state.allCars
    let dataPerDay = this.state.dataPerDay

    for (let i = 0; i < this.state.planningPage.state.selectCarsValues.length; i++) {
      if (i === inputValue) {
        allCars[index] = this.state.planningPage.state.selectCarsValues[index][i]
        let selectedCarsIndexes = this.state.selectedCarsIndexes
        selectedCarsIndexes[index] = inputValue
        this.setState({selectedCarsIndexes: selectedCarsIndexes})
        break
      }
    }

    let updatedCarsInputs = this.state.updatedCarsInputs
    updatedCarsInputs[index] = false

    for (let i = 0; i < allCars.length; i++) {
      if (i === index) {
        dataPerDay[i].car.name = allCars[i] !== "Keins" ? allCars[i] : undefined
        for (const car of this.state.planningPage.state.cars) {
          if (dataPerDay[i].car.name === car.name) {
            dataPerDay[i].car.capacity = car.capacity
            break
          }
        }
        break
      }
    }

    const {selectCarsValues, x} = this.PrepareCarsSelectValues(dataPerDay)

    this.state.planningPage.setState({selectCarsValues: selectCarsValues}, () => {
      this.setState({
        allCars: allCars,
        dataPerDay: dataPerDay
      }, () => {
        this.setState({updatedCarsInputs: updatedCarsInputs})
        this.UpdateCurrentHubInHubs(dataPerDay)
      })
    })
  }

  ChangedNextWeekDayInput = (inputValue) => {
    for (let i = 0; i < this.state.allNextWeekDays.length; i++) {
      if (i === inputValue) {
        this.setState({selectedNextWeekDayIndex: i})
        break
      }
    }

    this.setState({updatedNextWeekDayInput: false})
  }

  render() {
    return (
      <Card key={`hub-${this.state.hubData.name}-card`} variant="outlined"
        style={{
          width: this.state.isMobileVertical ? "90vw" : this.state.isMobile ? "94vw" : "100%",
          height: 135,
          marginBottom: "10px",
          background: "linear-gradient(60deg, rgba(50, 50, 50, 0.3), rgba(60, 60, 60, 0.3))",
          borderColor: "white",
          borderWidth: "1px"
        }}
      >
        {this.state.dataLoaded ?
          <CardContent style={{overflowX: this.state.isMobile ? "scroll" : "hidden"}}>
            <Box style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "20px"}}>
              <Typography variant={this.state.isMobileVertical ? "h6" : "h5"} style={{color: "#DFDFDF", marginTop: "-5px", marginBottom: "10px", fontWeight: "bold"}}>{this.state.hubData.name}</Typography>
              <Typography variant={this.state.isMobileVertical ? "body2" : "body1"} style={{color: "#DFDFDF", marginBottom: "10px"}}>{this.GetHubDetails()}</Typography>
            </Box>
            <Box style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "-10px"}}>
              {this.state.dataPerDay.map((dayData, index) => (
                !dayData.isNextDay || (this.state.selectedNextWeekDayIndex > 0 && dayData.day === this.state.allNextWeekDays[this.state.selectedNextWeekDayIndex]) ? (
                  <Box key={`hub-${this.state.hubData.name}-day-${dayData.day}-box-${index}`} style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "10px"}}>
                    <Box style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginLeft: !dayData.isNextDay ? "-20px" : "", marginRight: !dayData.isNextDay ? "" : "20px"}}>
                      <Typography variant={this.state.isMobileVertical ? "body1" : "h6"} style={{color: "#DFDFDF"}}>{dayData.day}</Typography>
                      <Typography variant={this.state.isMobileVertical ? "body2" : "body1"} style={{color: "#DFDFDF", marginTop: "5px"}}>{this.state.dates[index]}</Typography>
                    </Box>
                    <Box style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"}}>
                      <FormControl variant="outlined" style={{width: 120, height: 35, marginRight: !dayData.isNextDay ? "" : "20px"}}>
                        <InputLabel id={`hub-${this.state.hubData.name}-day-${dayData.day}-tours-select-${index}-label`} htmlFor="component-outlined" style={{color: "#DFDFDF"}}>Anzahl Touren</InputLabel>
                        <Select
                          labelId={`hub-${this.state.hubData.name}-day-${dayData.day}-tours-select-${index}-label`}
                          id={`hub-${this.state.hubData.name}-day-${dayData.day}-tours-select-${index}`}
                          style={{height: 35, color: this.state.allTours[index] > this.state.hubData.maxTours ? "#F3B607" : ""}}
                          value={this.state.selectedToursIndexes[index]}
                          displayEmpty={true}
                          renderValue={() => {return this.state.allTours[index]}}
                          label="Anzahl Touren"
                          MenuProps={{PaperProps: {style: {backgroundColor: "#333333"}}}}
                          onChange={(event) => {
                            let updatedToursInputs = this.state.updatedToursInputs
                            updatedToursInputs[index] = true
                            this.setState({updatedToursInputs: updatedToursInputs}, () => {this.ChangedToursInput(event.target.value, index)})}}
                        >
                          {this.state.selectToursValues.map((tour, i) => (
                            <MenuItem key={`hub-${this.state.hubData.name}-day-${dayData.day}-tours-menu-${index}-item-${i}`} value={i}>
                              <Typography variant="body2">{tour}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {!dayData.isNextDay ?
                        <FormControl variant="outlined" style={{width: 90, height: 35, marginRight: "20px"}}>
                          <InputLabel id={`hub-${this.state.hubData.name}-day-${dayData.day}-cars-select-${index}-label`} htmlFor="component-outlined" style={{color: "#DFDFDF"}}>Fahrzeug</InputLabel>
                          <Select
                            labelId={`hub-${this.state.hubData.name}-day-${dayData.day}-cars-select-${index}-label`}
                            id={`hub-${this.state.hubData.name}-day-${dayData.day}-cars-select-${index}`}
                            style={{height: 35}}
                            value={this.state.selectedCarsIndexes[index]}
                            displayEmpty={true}
                            renderValue={() => {return this.state.allCars[index]}}
                            label="Fahrzeug"
                            MenuProps={{PaperProps: {style: {backgroundColor: "#333333"}}}}
                            onChange={(event) => {
                              let updatedCarsInputs = this.state.updatedCarsInputs
                              updatedCarsInputs[index] = true
                              this.setState({updatedCarsInputs: updatedCarsInputs}, () => {this.ChangedCarsInput(event.target.value, index)})}}
                          >
                            {this.state.planningPage.state.selectCarsValues[index].map((car, i) => (
                              <MenuItem key={`hub-${this.state.hubData.name}-day-${dayData.day}-cars-menu-${index}-item-${i}`} value={i}>
                                <Typography variant="body2">{car}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      :null}
                    </Box>
                  </Box>
                ):(null)
              ))}
              <Box key={`hub-${this.state.hubData.name}-next-week-day-box`} style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "10px"}}>
                <Typography variant={this.state.isMobileVertical ? "body1" : "h6"} style={{color: "#DFDFDF"}}>Folgewoche</Typography>
                <FormControl variant="outlined" style={{width: 130, height: 35}}>
                  <InputLabel id={`hub-${this.state.hubData.name}-next-week-day-select-label`} htmlFor="component-outlined" style={{color: "#DFDFDF"}}>Tag auswählen</InputLabel>
                  <Select
                    labelId={`hub-${this.state.hubData.name}-next-week-day-select-label`}
                    id={`hub-${this.state.hubData.name}-next-week-day-select`}
                    style={{height: 35}}
                    value={this.state.selectedNextWeekDayIndex}
                    displayEmpty={true}
                    renderValue={() => {return this.state.allNextWeekDays[this.state.selectedNextWeekDayIndex]}}
                    label="Tag Folgewoche"
                    MenuProps={{PaperProps: {style: {backgroundColor: "#333333"}}}}
                    onChange={(event) => {this.setState({updatedNextWeekDayInput: true}, () => {this.ChangedNextWeekDayInput(event.target.value)})}}
                  >
                    {this.state.allNextWeekDays.map((nextWeekDay, i) => (
                      <MenuItem key={`hub-${this.state.hubData.name}-next-week-day-menu-item-${i}`} value={i}>
                        <Typography variant="body2">{nextWeekDay}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </CardContent>
        :null}
      </Card>
    )
  }
}

export default PlanningHub
