//Mixed & General imports
import React, { useState, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
//Firebase
import allTenantsMapping from "./firebase/tenants"
import loginUser from "./firebase/auth"
import getFirebaseLocalStorageElements from "./firebase/getLocalStorage"
//Backend
import authLogin from "./backend/authLogin"
//Own Components
import SignInButton from "./components/signInButton"
//Imports from Material-UI core
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, OutlinedInput, InputAdornment, IconButton, Box, Button, Snackbar, Alert } from "@mui/material"
//Imports from Material-UI icons
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"

export const LoginPage = (props) => {
  const router = useHistory()

  const allTenants = allTenantsMapping.map(tenant => tenant.name)

  const [selectedTenantIndex, setSelectedTenantIndex] = useState(0)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const [showMailError, setShowMailError] = useState(false)
  const [showFormError, setShowFormError] = useState(false)
  const [showCaptchaError, setShowCaptchaError] = useState(false)
  const [showAccessTokenError, setShowAccessTokenError] = useState(false)
  const [showAuthLoginError, setShowAuthLoginError] = useState(false)

  const [reCaptchaToken, setReCaptchaToken] = useState(undefined)
  const {executeRecaptcha} = useGoogleReCaptcha()

  const [isMobile, setIsMobile] = useState(false)
  const [isMobileVertical, setIsMobileVertical] = useState(false)

  const handleReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {return}

    try {
      const token = await executeRecaptcha("signup")

      setReCaptchaToken(token)
    } catch {}
  }, [executeRecaptcha, props.MODE])

  const checkMobileView = () => {
    // Update the state based on the screen width
    // If you change something here, you must change it in any other page
    setIsMobileVertical(window.innerWidth <= 1000 && window.innerHeight > window.innerWidth)
    setIsMobile((window.innerWidth <= 1700 && window.innerWidth > window.innerHeight) || isMobileVertical)
  }

  useEffect(() => {
    // Check if the URL includes "/login"
    const currentURL = window.location.href
    if (!currentURL.includes("/login")) {
      router.push("/login")
    }

    if (!reCaptchaToken) {
      handleReCaptchaToken()
    }

    window.addEventListener("resize", checkMobileView)
    checkMobileView()

  }, [handleReCaptchaToken, checkMobileView])

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (isMobile && window.innerWidth <= 1000 && window.innerHeight > window.innerWidth && !isMobileVertical) {
      checkMobileView()
    }

    if (isMobileVertical && window.innerWidth <= 1700 && window.innerWidth > window.innerHeight && !isMobile) {
      checkMobileView()
    }
  })

  const hitEnterToLogin = async (keyCode) => {
    if (keyCode === "Enter") {
      if (reCaptchaToken) {
        if (email !== "" && password !== "") {
          try {
            const user = await loginUser(email, password, allTenants[selectedTenantIndex])
            
            if (user) {
              try {
                const firebaseLocalStorage = await getFirebaseLocalStorageElements()
                if (firebaseLocalStorage.length > 0) {
                  const firebaseAccessToken = firebaseLocalStorage[0].value.stsTokenManager.accessToken
                  const authLoginResponse = await authLogin(reCaptchaToken, firebaseAccessToken)
                  console.log(authLoginResponse)
                } else {
                  setShowAccessTokenError(true)
                }
              } catch (error) {
                if (process.env.REACT_APP_MODE === "local") {console.error(error)}
                setShowAuthLoginError(true)
              }
            } else {
              setShowFormError(true)
            }
          } catch (error) {
            setShowFormError(true)
          }
        } else {
          setShowFormError(true)
        }
      } else {
        setShowCaptchaError(true)
      }
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{width: "100%", height: "100%"}}
    >
      <Grid item style={{width: "350px"}}>
        <Typography align="center" variant="h4" style={{color: "#D2D2D2", marginBottom: "20px", width: 350}}>Login</Typography>
        <FormControl variant="outlined" style={{width: 350, marginBottom: "30px"}}>
          <InputLabel id={`login-tenant-select-label`} htmlFor="component-outlined" style={{color: "#DFDFDF"}}>Tenant-Auswahl</InputLabel>
          <Select
            labelId={`login-tenant-select-label`}
            id={`login-tenant-select`}
            value={selectedTenantIndex}
            displayEmpty={true}
            renderValue={() => {return allTenants[selectedTenantIndex]}}
            label="Tenant-Auswahl"
            MenuProps={{PaperProps: {style: {backgroundColor: "#333333"}}}}
            onChange={(event) => {setSelectedTenantIndex(event.target.value)}}
          >
            {allTenants.map((tenant, i) => (
              <MenuItem key={`login-tenant-menu-${i}`} value={i}>
                <Typography variant="body2">{tenant}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{marginBottom: "30px", width: 350}}>
          <InputLabel htmlFor="component-outlined" style={{color: "#D2D2D2"}}>E-Mail</InputLabel>
          <OutlinedInput
              id={"component-outlined-registration-email"}
              type="email"
              name="email"
              onChange={(event) => {setEmail(event.target.value)}}
              onKeyDown={(event) => {hitEnterToLogin(event.code)}}
              label="E-Mail"
              value={email}
          />
        </FormControl>
        <FormControl variant="outlined" style={{marginBottom: "20px", width: 350}}>
          <InputLabel htmlFor="component-outlined" style={{color: "#D2D2D2"}}>Passwort</InputLabel>
          <OutlinedInput
            id={"component-outlined-registration-password"}
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={(event) => {setPassword(event.target.value)}}
            onKeyDown={(event) => {hitEnterToLogin(event.code)}}
            label="Passwort"
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle-password-visibility"
                  onClick={() => {setShowPassword(!showPassword)}}
                  onMouseDown={handleMouseDownPassword}
                  style={{color: "#D2D2D2", marginRight: "-10px"}}
                >
                  {showPassword ? <VisibilityOutlined fontSize={"medium"}/> : <VisibilityOffOutlined fontSize={"medium"}/>}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <SignInButton color="dark" style={{marginBottom: "10px", width: 350, color: "#EEEEEE"}} onClick={async () => {hitEnterToLogin("Enter")}}>Anmelden</SignInButton>
        <Box display="flex" justifyContent="center" style={{marginTop: "10px", width: 350}}>
          <Button style={{color: "#DFDFDF"}} onClick={() => {router.push("/impressum")}}>Impressum</Button>
        </Box>
        <Box display="flex" style={{width: 350}}>
          <Typography variant="caption" align="center" style={{color: "#DFDFDF", marginTop: "5px", width: 350}}>Copyright © CFG AG {new Date().getFullYear()}</Typography>
        </Box>
        {showMailError ?
          <Snackbar open={true} autoHideDuration={5000} onClose={() => {setShowMailError(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {setShowMailError(false)}} severity="error">
              Die verwendete E-Mail ist nicht korrekt.
            </Alert>
          </Snackbar>
        :null}
        {showFormError ?
          <Snackbar open={true} autoHideDuration={7500} onClose={() => {setShowFormError(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {setShowFormError(false)}} severity="error">
              Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihre Angaben. Die E-Mail oder das Passwort ist nicht korrekt.
            </Alert>
          </Snackbar>
        :null}
        {showCaptchaError ?
          <Snackbar open={true} autoHideDuration={5000} onClose={() => {setShowCaptchaError(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {setShowCaptchaError(false)}} severity="error">
              Das Captcha ist leider ungültig. Laden Sie die Seite neu und versuchen Sie es nochmals oder leeren Sie den Cache Ihres Browsers.
            </Alert>
          </Snackbar>
        :null}
        {showAccessTokenError ?
          <Snackbar open={true} autoHideDuration={5000} onClose={() => {setShowAccessTokenError(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {setShowAccessTokenError(false)}} severity="error">
              Die Anmeldung ist fehlgeschlagen. Das Zugriffstoken für die Validierung der Anmeldung ist ungültig.
            </Alert>
          </Snackbar>
        :null}
        {showAuthLoginError ?
          <Snackbar open={true} autoHideDuration={5000} onClose={() => {setShowAuthLoginError(false)}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {setShowAuthLoginError(false)}} severity="error">
              Die Anmeldung ist fehlgeschlagen. Das Zugriffstoken & das reCaptcha-Token für die Anmeldung konnten nicht validiert werden.
            </Alert>
          </Snackbar>
        :null}
      </Grid>
    </Grid>
  )
}

export default LoginPage
