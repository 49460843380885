export default function getFirebaseLocalStorageElements() {
  return new Promise((resolve, reject) => {
    // Open the `firebaseLocalStorageDb` database
    const request = indexedDB.open("firebaseLocalStorageDb", 1)

    request.onsuccess = function (event) {
      const db = event.target.result

      // Start a transaction to read data from the `firebaseLocalStorage` object store
      const transaction = db.transaction(["firebaseLocalStorage"], "readonly")
      const objectStore = transaction.objectStore("firebaseLocalStorage")

      // Get all the data in the object store
      const getAllRequest = objectStore.getAll()

      getAllRequest.onsuccess = function (event) {
        // Resolve the promise with the retrieved data
        resolve(event.target.result)
      }

      getAllRequest.onerror = function (event) {
        // Reject the promise in case of an error
        reject("Error retrieving data from firebaseLocalStorage: " + event.target.error)
      }
    }

    request.onerror = function (event) {
      // Reject the promise if the database fails to open
      reject("Error opening firebaseLocalStorageDb: " + event.target.error)
    }
  })
}
