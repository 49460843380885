class HeaderGlobalClass {
  constructor (initialState) {
    this.state = initialState
  }

  setClass (state) {
    this.state = state
  }

  getClass () {
    return this.state
  }
}

const headerClass = new HeaderGlobalClass({})
export default headerClass
