//Mixed & General imports
import React from "react"
//Own Components
import GetDataLoadingCircle from "../utils/getDataLoadingCircle"
import PlanningButton from "./components/planningButton"
import PlanningHub from "./components/planningHub"
import PlanningOverview from "./components/planningOverview"
import PlanningCarsInformation from "./components/planningCarsInformation"
//Own Util Functions
import GetTheme from "../utils/getTheme"
import CalculatePlannedTons from "./utils/calculatePlannedTons"
import CalculatePlannedTonsDeviation from "./utils/calculatePlannedTonsDeviation"
//Own Constants
import { defaultTheme } from "../../defaults/constants"
//Imports from Material-UI core
import { CssBaseline, Box, FormControl, InputLabel, Select, MenuItem, Typography, Snackbar } from "@mui/material"
//Imports from Material-UI lab
import { Alert } from "@mui/lab"
//Imports from Material-UI icons
import { EastOutlined } from "@mui/icons-material"
//Imports from Material-UI styles
import { ThemeProvider } from "@mui/material/styles"

class PlanningPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
      isMobileVertical: false,
      theme: defaultTheme,
      dataLoaded: false,
      
      //PlanningHub
      planningHubsKey: Math.random(),
      cars: [],
      availableCarsOnDays: [],
      selectCarsValues: [],

      //PlanningOverview
      planningOverviewKey: Math.random(),

      //PlanningCarsInformation
      planningCarsInformationKey: Math.random(),

      //ChangedWeekInput()
      weeks: [],
      updatedWeekInput: false,
      selectedWeekIndex: 0,
      week: "",
      weekSelected: false,
      hubs: [],  //hubData and dataPerDay per entry => required in planningHub.js
      hubsLoaded: false,

      //CreateAndShowProposal()
      proposalCreated: false,
      createdPorposal: undefined,
      proposalShowed: false,
      showedPorposal: undefined,

      //SaveProposal()
      proposalSaved: false,
      savedPorposal: undefined,

      //Alerts
      showSaveProposalPlannedTonsWarning: false,
      showSaveProposalMaxToursWarning: false,
      showHubsNotLoadedWarning: false,

      showAllWeeksOfAvailableForecastError: false,
      showLoadHubsError: false,
      showLoadCarsError: false,
      showCreateAndShowProposalError: false,
      showSaveProposalError: false,
      showStartDispositionsError: false,
      showSendRoutesError: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.CheckMobileView)
    this.CheckMobileView()
    this.SetCurrentUser()
    this.GetAllWeeksOfAvailableForecast()
  }

  componentDidUpdate() {
    if (this.state.isMobile && window.innerWidth <= 1000 && window.innerHeight > window.innerWidth && !this.state.isMobileVertical) {
      this.CheckMobileView()
    }

    if (this.state.isMobileVertical && window.innerWidth <= 1700 && window.innerWidth > window.innerHeight && !this.state.isMobile) {
      this.CheckMobileView()
    }
  }

  CheckMobileView = () => {
    // Update the state based on the screen width
    // If you change something here, you must change it in any other page
    const isMobile = window.innerWidth <= 1700 && window.innerWidth > window.innerHeight // Adjust the breakpoint as per your needs
    const isMobileVertical = window.innerWidth <= 1000 && window.innerHeight > window.innerWidth
    this.setState({
      isMobile: isMobile || isMobileVertical,
      isMobileVertical: isMobileVertical
    })
  }

  SetTheme = (currentUser) => {
    const theme = GetTheme(currentUser)
    this.setState({theme: theme})
  }

  SetCurrentUser = async () => {
    let currentUser = undefined
    try {
      currentUser = await getCurrentUser()
    } catch (error) {
      if (process.env.MODE === "local") {console.error(error)}
    }
    this.SetTheme(currentUser)
  }

  GetAllWeeksOfAvailableForecast = () => {
    let weeks = [
      {
        label: "KW 1",
        dates: ["01.01.2024", "02.01.2024", "03.01.2024", "04.01.2024", "05.01.2024", "06.01.2024", "07.01.2024"]
      },
      {
        label: "KW 2",
        dates: ["08.01.2024", "09.01.2024", "10.01.2024", "11.01.2024", "12.01.2024", "13.01.2024", "14.01.2024"]
      },
      {
        label: "KW 3",
        dates: ["15.01.2024", "16.01.2024", "17.01.2024", "18.01.2024", "19.01.2024", "20.01.2024", "21.01.2024"]
      }
    ]
    let week = {
      label: "",
      dates: []
    }
    
    try {
      //Get all available Weeks from Backend
    } catch (error) {
      if (process.env.REACT_APP_MODE === "local") {console.error(error)}
      this.setState({showAllWeeksOfAvailableForecastError: true})
    }

    this.setState({
      weeks: weeks,
      week: weeks[0].label
    }, () => {this.setState({dataLoaded: true})})
  }

  ChangedWeekInput = (inputValue, reset=false) => {
    let weeks = this.state.weeks
    let week = undefined

    for (let i = 0; i < weeks.length; i++) {
      if (i === inputValue) {
        week = weeks[i].label
        this.setState({selectedWeekIndex: inputValue})
      }
    }

    let weekSelected = true
    let hubs = this.state.hubs
    let hubsLoaded = this.state.hubsLoaded
    if (reset) {
      weekSelected = false
      hubs = []
      hubsLoaded = false
    }

    this.setState({
      planningHubsKey: Math.random(),
      planningOverviewKey: Math.random(),
      week: week,
      weekSelected: weekSelected,
      hubs: hubs,
      hubsLoaded: hubsLoaded,
      proposalShowed: false,
      showedPorposal: undefined,
      proposalCreated: false,
      createdPorposal: undefined,
      proposalSaved: false,
      savedPorposal: undefined
    }, async () => {
      this.setState({updatedWeekInput: true})
      await this.LoadHubs()
    })
  }

  LoadHubs = async () => {
    const selectedWeek = this.state.week

    try {
      //Load hubs from Backend by selectedWeek (dataPerDay depends on this)
      //const hubs = await ...
    } catch (error) {
      if (process.env.REACT_APP_MODE === "local") {console.error(error)}
      this.setState({showLoadHubsError: true})
    }

    const hubs = [
      {
        hubData: {name: "Weinfelden", tonsPerWeek: 97, plannedTons: undefined, minTours: 1, maxTours: 3},
        dataPerDay: [
          {day: "Montag", isNextDay: false, tours: 4, car: {name: "Fz 1", capacity: 9.5}},
          {day: "Dienstag", isNextDay: false, tours: 1, car: {name: "Fz 3", capacity: 9.5}},
          {day: "Mittwoch", isNextDay: false, tours: 3, car: {name: "Fz 2", capacity: 9.5}},
          {day: "Donnerstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Freitag", isNextDay: false, tours: 2, car: {name: "Fz 5", capacity: 9.5}},
          {day: "Samstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Sonntag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Montag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}}
        ]
      },
      {
        hubData: {name: "Kreuzlingen", tonsPerWeek: 78, plannedTons: undefined, minTours: 1, maxTours: 3},
        dataPerDay: [
          {day: "Montag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: false, tours: 2, car: {name: "Fz 2", capacity: 9.5}},
          {day: "Mittwoch", isNextDay: false, tours: 3, car: {name: "Fz 4", capacity: 9.5}},
          {day: "Donnerstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Freitag", isNextDay: false, tours: 3, car: {name: "Fz 4", capacity: 9.5}},
          {day: "Samstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Sonntag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Montag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}}
        ]
      },
      {
        hubData: {name: "Frauenfeld", tonsPerWeek: 97, plannedTons: undefined, minTours: 2, maxTours: 3},
        dataPerDay: [
          {day: "Montag", isNextDay: false, tours: 1, car: {name: "Fz 2", capacity: 9.5}},
          {day: "Dienstag", isNextDay: false, tours: 3, car: {name: "Fz 4", capacity: 9.5}},
          {day: "Mittwoch", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Donnerstag", isNextDay: false, tours: 3, car: {name: "Fz 5", capacity: 9.5}},
          {day: "Freitag", isNextDay: false, tours: 2, car: {name: "Fz 2", capacity: 9.5}},
          {day: "Samstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Sonntag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Montag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}}
        ]
      },
      {
        hubData: {name: "Romanshorn Ost 1", tonsPerWeek: 69, plannedTons: undefined, minTours: 1, maxTours: 2},
        dataPerDay: [
          {day: "Montag", isNextDay: false, tours: 3, car: {name: "Fz 4", capacity: 9.5}},
          {day: "Dienstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: false, tours: 2, car: {name: "Fz 1", capacity: 9.5}},
          {day: "Donnerstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Freitag", isNextDay: false, tours: 2, car: {name: "Fz 3", capacity: 9.5}},
          {day: "Samstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Sonntag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Montag", isNextDay: true, tours: 2, car: {name: "Fz 1", capacity: 9.5}},
          {day: "Dienstag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}}
        ]
      },
      {
        hubData: {name: "Romanshorn Ost 2", tonsPerWeek: 62, plannedTons: undefined, minTours: 1, maxTours: 2},
        dataPerDay: [
          {day: "Montag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: false, tours: 2, car: {name: "Fz 5", capacity: 9.5}},
          {day: "Mittwoch", isNextDay: false, tours: 2, car: {name: "Fz 3", capacity: 9.5}},
          {day: "Donnerstag", isNextDay: false, tours: 1, car: {name: "Fz 1", capacity: 9.5}},
          {day: "Freitag", isNextDay: false, tours: 2, car: {name: "Fz 1", capacity: 9.5}},
          {day: "Samstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Sonntag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Montag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}}
        ]
      },
      {
        hubData: {name: "Stein am Rhein", tonsPerWeek: 59, plannedTons: undefined, minTours: 1, maxTours: 2},
        dataPerDay: [
          {day: "Montag", isNextDay: false, tours: 2, car: {name: "Fz 3", capacity: 9.5}},
          {day: "Dienstag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Donnerstag", isNextDay: false, tours: 2, car: {name: "Fz 3", capacity: 9.5}},
          {day: "Freitag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Samstag", isNextDay: false, tours: 2, car: {name: "Fz 5", capacity: 9.5}},
          {day: "Sonntag", isNextDay: false, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Montag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Dienstag", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}},
          {day: "Mittwoch", isNextDay: true, tours: undefined, car: {name: undefined, capacity: undefined}}
        ]
      }
    ]

    try {
      //Load all cars from backend (Stammdaten)
      //const cars = await ...
    } catch (error) {
      if (process.env.REACT_APP_MODE === "local") {console.error(error)}
      this.setState({showLoadCarsError: true})
    }
    
    const cars = [
      {name: "Fz 1", capacity: 9.5, location: "Weinfelden"},
      {name: "Fz 2", capacity: 9.5, location: "Kreuzlingen"},
      {name: "Fz 3", capacity: 9.5, location: "Frauenfeld"},
      {name: "Fz 4", capacity: 9.5, location: "Romanshorn Ost 1"},
      {name: "Fz 5", capacity: 9.5, location: "Stein am Rhein"}
    ]

    this.setState({
      hubs: hubs,
      hubsLoaded: true,
      cars: cars
    })
  }

  DetermineAvailableCarsOnDays = (hubs) => {
    const cars = this.state.cars
    let usedCarsOnDays = []
    let availableCarsOnDays = []

    for (const hub of hubs) {
      for (const data of hub.dataPerDay) {
        const carData = {
          day: data.isNextDay ? `${data.day}-2` : data.day,
          name: data.car.name
        }

        let foundCar = usedCarsOnDays.some(car => car.day === carData.day && car.name === carData.name)

        if (!foundCar && carData.name) {
          usedCarsOnDays.push(carData)
        }
      }
    }

    for (const data of hubs[0].dataPerDay) {
      const day = data.isNextDay ? `${data.day}-2` : data.day

      const availableCars = cars.filter(car => 
        !usedCarsOnDays.some(usedCar => usedCar.day === day && usedCar.name === car.name)
      )

      availableCarsOnDays.push({
        day: day,
        cars: availableCars
      })
    }

    this.setState({availableCarsOnDays: availableCarsOnDays})

    return availableCarsOnDays
  }

  CreateAndShowProposal = async () => {
    const selectedWeek = this.state.week

    try {
      //Create Proposal from Backend
      //const proposal = await ...
    } catch (error) {
      if (process.env.REACT_APP_MODE === "local") {console.error(error)}
      this.setState({showCreateAndShowProposalError: true})
    }
    
    const createdPorposal = undefined

    this.setState({
      proposalCreated: true,
      createdPorposal: createdPorposal,
      proposalShowed: false,
      showedPorposal: undefined,
      proposalSaved: false,
      savedPorposal: undefined
    }, () => {this.ShowProposal(selectedWeek,  createdPorposal)})
  }

  ShowProposal = async (selectedWeek, showedPorposal) => {
    //Show propsal in UI
    //...

    this.setState({
      proposalShowed: true,
      showedPorposal: showedPorposal,
      proposalSaved: false,
      savedPorposal: undefined
    })
  }

  SaveProposal = async () => {
    const selectedWeek = this.state.week
    const hubs = this.state.hubs

    if (this.state.hubsLoaded) {
      let allPlannedTonsFine = true

      for (const hub of hubs) {
        let plannedTons = hub.hubData.plannedTons
        if (!plannedTons) {
          plannedTons = CalculatePlannedTons(hub.dataPerDay)
        }

        const [color, deviationPercentage] = CalculatePlannedTonsDeviation(plannedTons, hub.hubData.tonsPerWeek)

        if (deviationPercentage < 95 || deviationPercentage > 105) {
          allPlannedTonsFine = false
          break
        }
      }

      if (allPlannedTonsFine) {
        try {
          //Save Proposal to Backend
          //const proposal = await ...
        } catch (error) {
          if (process.env.REACT_APP_MODE === "local") {console.error(error)}
          this.setState({showSaveProposalError: true})
        }
        
        const savedPorposal = undefined

        this.setState({
          proposalSaved: true,
          savedPorposal: savedPorposal
        })
      } else {
        this.setState({showSaveProposalPlannedTonsWarning: true})
      }
    } else {
      this.setState({showHubsNotLoadedWarning: true})
    }
  }

  StartDispositions_And_SendRoutes = async () => {
    const selectedWeek = this.state.week

    //Add exception handling
    await this.StartDispositions()
    await this.SendRoutes()

    //Alert to user, that everyhting worked fine or not (success, error)

    this.setState({
      weekSelected: false,
      proposalShowed: false,
      showedPorposal: undefined,
      proposalCreated: false,
      createdPorposal: undefined,
      proposalSaved: false,
      savedPorposal: undefined
    }, () => {this.ChangedWeekInput(0, true)})
  }

  StartDispositions = async () => {
    try {
      //Start Dispositions in Backend
      //Do not await finish, just request
    } catch (error) {
      if (process.env.REACT_APP_MODE === "local") {console.error(error)}
      this.setState({showStartDispositionsError: true})
    }
  }

  SendRoutes = async () => {
    try {
      //Send routes to Backend
      //Do not await finish, just request
    } catch (error) {
      if (process.env.REACT_APP_MODE === "local") {console.error(error)}
      this.setState({showSendRoutesError: true})
    }
  }

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <div style={{height: "100%", overflowX: this.state.dataLoaded ? "scroll" : "", overflowY: "auto", position: "fixed", top: 0, right: 0, bottom: 0, left: 0}}>
          {this.state.dataLoaded ?
            <Box style={{margin: "20px", marginTop: "105px"}}>
              {/* Week input and Buttons handling */}
              <Box style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "20px", marginBottom: "15px"}}>
                <FormControl variant="outlined" style={{width: this.state.isMobileVertical ? "90vw" : this.state.isMobile ? "94vw" : 220}}>
                  <InputLabel id="planning-week-select-label" htmlFor="component-outlined" style={{color: "#DFDFDF"}}>Woche auswählen</InputLabel>
                  <Select
                    labelId="planning-week-select-label"
                    id="planning-week-select"
                    value={this.state.selectedWeekIndex}
                    displayEmpty={true}
                    renderValue={() => {return this.state.week}}
                    label="Woche auswählen"
                    MenuProps={{PaperProps: {style: {backgroundColor: "#333333"}}}}
                    onChange={(event) => {this.setState({updatedWeekInput: false}, () => {this.ChangedWeekInput(event.target.value)})}}
                  >
                    {this.state.weeks.map((week, index) => (
                      <MenuItem key={"planning-week-menu-item-" + index} value={index}>
                        <Typography variant="body2">{week.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {this.state.weekSelected ?
                  <>
                    <EastOutlined fontSize="large" style={{marginRight: "-10px"}}/>
                    <PlanningButton color="blue" fontColor="black" height={50} style={{width: 220}} onClick={() => {this.CreateAndShowProposal()}}>Vorschlag erstellen & anzeigen</PlanningButton>
                  </>
                :null}
                {this.state.proposalCreated && this.state.proposalShowed ?
                  <>
                    <EastOutlined fontSize="large" style={{marginLeft: "-10px", marginRight: "-10px"}}/>
                    <PlanningButton color="yellow" fontColor="black" height={50} style={{width: 220}} onClick={() => {this.SaveProposal()}}>Vorschlag speichern</PlanningButton>
                  </>
                :null}
                {this.state.proposalSaved ?
                  <>
                    <EastOutlined fontSize="large" style={{marginLeft: "-10px", marginRight: "-10px"}}/>
                    <PlanningButton color="green" fontColor="black" height={50} style={{width: 220}} onClick={() => {this.StartDispositions_And_SendRoutes()}}>Dispositionen starten & Routen senden</PlanningButton>
                  </>
                :null}
              </Box>
              <Box style={{display: "flex", flexDirection: "row", gap: this.state.isMobile ? "20px" : ""}}>
                {this.state.hubsLoaded ?
                  <>
                    {/* Hubs */}
                    <div key={`planning-hubs-${this.state.planningHubsKey}`} style={{marginRight: "30px"}}>
                      {this.state.hubs.map((hub, index) => (
                        <Box key={`planning-hub-${hub.hubData.name}-box-${index}`}>
                          <PlanningHub
                            planningPage={this}
                            isMobile={this.state.isMobile}
                            isMobileVertical={this.state.isMobileVertical}
                            weeks={this.state.weeks}
                            selectedWeekIndex={this.state.selectedWeekIndex}
                            hubIndex={index}
                            hubData={hub.hubData}
                            dataPerDay={hub.dataPerDay}
                          />
                        </Box>
                      ))}
                    </div>
                    <Box style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                      {/* Overview */}
                      <div key={`planning-overview-${this.state.planningOverviewKey}`}>
                        <PlanningOverview
                          planningPage={this}
                          isMobile={this.state.isMobile}
                          isMobileVertical={this.state.isMobileVertical}
                        />
                      </div>
                      {/* Cars information */}
                      <div key={`planning-cars-information-${this.state.planningCarsInformationKey}`}>
                        <PlanningCarsInformation
                          planningPage={this}
                          isMobile={this.state.isMobile}
                          isMobileVertical={this.state.isMobileVertical}
                        />
                      </div>
                    </Box>
                  </>
                :null}
              </Box>
            </Box>
          :GetDataLoadingCircle()}
        </div>
        {this.state.showSaveProposalPlannedTonsWarning ?
          <Snackbar open={true} autoHideDuration={7500} onClose={() => {this.setState({showSaveProposalPlannedTonsWarning: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showSaveProposalPlannedTonsWarning: false})}} severity="warning">
              Es sind nicht alle geplanten Tonnen für jeden Hub im grünen Bereich. Der Vorschlag kann nicht gespeichert werden.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showSaveProposalMaxToursWarning ?
          <Snackbar open={true} autoHideDuration={7500} onClose={() => {this.setState({showSaveProposalMaxToursWarning: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showSaveProposalMaxToursWarning: false})}} severity="warning">
              Die maximale Anzahl Touren wurden manuell überschrieben.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showHubsNotLoadedWarning ?
          <Snackbar open={true} autoHideDuration={7500} onClose={() => {this.setState({showHubsNotLoadedWarning: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showHubsNotLoadedWarning: false})}} severity="warning">
              Die Daten für die Hubs sind nicht geladen. Der Vorschlag kann nicht gespeichert werden.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showAllWeeksOfAvailableForecastError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showAllWeeksOfAvailableForecastError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showAllWeeksOfAvailableForecastError: false})}} severity="error">
              Es konnten keine Wochen zum vorhandenen Forecast geladen werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showLoadHubsError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showLoadHubsError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showLoadHubsError: false})}} severity="error">
              Die Hubs konnten nicht geladen werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showLoadCarsError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showLoadCarsError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showLoadCarsError: false})}} severity="error">
              Die Fahrzeuge konnten nicht geladen werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showCreateAndShowProposalError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showCreateAndShowProposalError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showCreateAndShowProposalError: false})}} severity="error">
              Es konnte kein Vorschlag erstellt und angezeigt werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showSaveProposalError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showSaveProposalError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showSaveProposalError: false})}} severity="error">
              Der Vorschlag konnte nicht gespeichert werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showStartDispositionsError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showStartDispositionsError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showStartDispositionsError: false})}} severity="error">
              Der Dispositionen konnten nicht gestartet werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
        {this.state.showSendRoutesError ?
          <Snackbar open={true} autoHideDuration={10000} onClose={() => {this.setState({showSendRoutesError: false})}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={() => {this.setState({showSendRoutesError: false})}} severity="error">
              Die Routen konnten nicht gesendet werden. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support.
            </Alert>
          </Snackbar>
        :null}
      </ThemeProvider>
    )
  }
}

export default PlanningPage
