export default function CalculatePlannedTons(dataPerDay) {
  let plannedTons = 0

  for (const data of dataPerDay) {
    if (data.tours && data.car.capacity) {
      plannedTons += data.tours * data.car.capacity
    }
  }

  return plannedTons
}
