// Mixed & General imports
import React from "react"
import PropTypes from "prop-types"
// Imports from Material-UI core
import Button from "@mui/material/Button"
// Imports from Material-UI styles
import { styled } from "@mui/material/styles"

const MyButton = styled(Button)(() => ({
  background: "linear-gradient(45deg, #333333 30%, #333333 90%)",
  border: 0,
  borderRadius: 4,
  boxShadow: "0 3px 5px 2px rgba(194, 193, 193, .3)",
  color: "white",
  height: 48,
  width: 300,
  padding: "0 15px",
}))

function SignInButton(props) {
  const { color, ...other } = props
  return <MyButton {...other} />
}

SignInButton.propTypes = {
  color: PropTypes.oneOf(["dark", "light"]).isRequired,
}

export default SignInButton
