//Mixed & General imports
import React from "react"
//Own Components
import GetCircularProgress from "./getCircularProgress"
//Imports from Material-UI v5
import { Box, Typography } from "@mui/material"

export default function GetDataLoadingCircle(inComponent=false, progress="0") {
  return (
    <>
      {inComponent ?
        <Box sx={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", position: "relative"}}>
          {GetCircularProgress(true)}
          <Typography variant="subtitle2" style={{color: "#C2C1C1", margin: "10px", position: "absolute"}}>{progress} %</Typography>
        </Box>
      :
        <Box sx={{display: "flex", width: "100%", height: "100%", zIndex: 10, justifyContent: "center", alignItems: "center", position: "absolute", top: 0}}>
          {GetCircularProgress(true)}
        </Box>
      }
    </>
  )
}
