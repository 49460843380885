export default function CalculatePlannedTonsDeviation(plannedTons, tonsPerWeek) {
  const deviationPercentage = (plannedTons / tonsPerWeek) * 100

  let color = "#29BC4B" // Default green
  if (deviationPercentage < 95) {
    color = "#EA4434"  // red
  } else if (deviationPercentage > 105) {
    color = "#F3B607"  // yellow
  }

  return [color, deviationPercentage]
}
