function CalculateColumnWidth(rows, headerName, dataKey) {
  const headerLength = headerName.length
  const maxContentLength = Math.max(...rows.map(row => (row[dataKey] ? row[dataKey].toString().length : 0)))

  return Math.max(headerLength, maxContentLength) * 11
}

export function SetDynamicColumnWidths(component, columns, rows) {
  const dynamicColumns = columns.map(column => ({
    ...column,
    width: CalculateColumnWidth(rows, column.headerName, column.field)
  }))
  
  component.setState({columns: dynamicColumns}, () => {component.setState({dataLoaded: true})});
}

export function GetSumOfColumnWidths(columns) {
  return columns.reduce((total, column) => total + (column.width || 0), 0) + 5
}
