//Mixed & General imports
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter, Switch, Route } from "react-router-dom"
//Own Components => Routes
import Layout from "./core/layouts/layout"
import Header from "./header/header"
import LoginWrapper from "./loginPage/loginWrapper"
import Guide from "./guidePage/guide"
import Faq from "./faqPage/faq"
import PlanningPage from "./planningPage/planning" //=> PlanningPage
import Impressum from "./impressumPage/impressum"
import Profile from "./profilePage/profile"

const root = createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap"/>
    <Layout title="CFG: Dynamic Collection Planning">
      <BrowserRouter>
        <Header MODE={process.env.REACT_APP_MODE} />
        <Switch>
          <Route exact path="/" component={LoginWrapper} />
          <Route path="/login" component={LoginWrapper} />
          <Route path="/guide" component={Guide} />
          <Route path="/faq" component={Faq} />
          <Route path="/planning" component={PlanningPage} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/profile" component={Profile} />
        </Switch>
      </BrowserRouter>
    </Layout>
  </React.StrictMode>
)
